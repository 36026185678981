<template>
  <div class="chart-h5-wrap">
    <div class="chart-tab">
      <span
        class="chart-tab-item"
        :class="{'active': chartType === 'day'}"
        @click="chartType = 'day'"
      >日营业额趋势图</span>
      <span
        class="chart-tab-item"
        :class="{'active': chartType === 'month'}"
        @click="chartType = 'month'"
      >月营业额趋势图</span>
    </div>
    <div class="chart-body">
      <div class="summary">
        <span>{{chartType === 'day' ? '日营业额' : '月营业额'}} [ {{currentItem.date}} ]</span>
        <span>{{currentItem.amount}}</span>
      </div>
      <div id="e-chart">
      </div>
    </div>
  </div>
</template>

<script>
import { getPerformanceByDay, getPerformanceByMonth } from 'api/performance'
import { setSessionStorage } from 'utils/store'
import moment from 'moment'

const ratio = document.body.clientWidth / 750

export default {
  data () {
    return {
      eChart: null,
      showBlankChart: true,
      chartType: 'day',
      data: [],
      currentItem: {
        index: 0,
        date: '',
        amount: ''
      }
    }
  },
  computed: {
    chartOption () {
      return {
        baseOption: {
          animation: true,
          tooltip: {
            trigger: 'axis',
            backgroundColor: '#4081D6',
            textStyle: {
              fontSize: 24 * ratio
            },
            axisPointer: {
              type: 'line'
            },
            alwaysShowContent: true,
            formatter: this.getToolTipContent,
            position: function (point, params, dom, rect, size) {
              return [point[0] - size.contentSize[0] / 2, 0]
            },
            confine: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [],
            axisPointer: {
              snap: false,
              z: 0,
              lineStyle: {
                color: '#4e4e4e',
                opacity: 1,
                type: 'solid',
                width: 1 * ratio
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              margin: 20 * ratio,
              fontSize: 22 * ratio,
              interval: 0,
              textStyle: {
                color: '#999999'
              }
            },
            splitLine: {
              show: false
            }
          },
          yAxis: {
            type: 'value',
            position: 'left',
            splitNumber: 6,
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dotted',
                color: '#e4e4e4'
              }
            },
            nameLocation: 'middle',
            axisLabel: {
              show: true,
              inside: false,
              margin: 20 * ratio,
              fontSize: 22 * ratio,
              formatter: '{value}\n',
              textStyle: {
                color: '#999999'
              }
            }
          },
          grid: {
            top: 32 * ratio,
            left: 15 * ratio,
            right: 45 * ratio,
            bottom: '5%',
            containLabel: true
          },
          series: [{
            type: 'line',
            showSymbol: true,
            symbol: 'emptyCircle',
            symbolSize: 16 * ratio,
            itemStyle: {
              normal: {
                color: '#4081D6'
              }
            },
            areaStyle: {
              normal: {
                color: 'rgba(64, 129, 214, 0.11)'
              }
            },
            data: []
          }]
        },
        media: [{
          query: {
            maxWidth: 360
          },
          option: {
            xAxis: {
              axisLabel: {
                fontSize: 20 * ratio
              }
            },
            yAxis: {
              axisLabel: {
                fontSize: 22 * ratio
              }
            }
          }
        },
        {
          option: {
            xAxis: {
              axisLabel: {
                fontSize: 22 * ratio
              }
            },
            yAxis: {
              axisLabel: {
                fontSize: 22 * ratio
              }
            }
          }
        }
        ]
      }
    }
  },
  methods: {
    formatDate (time, format = 'YYYY-MM-DD') {
      let date = moment(new Date(time)).format(format)
      return (date === 'Invalid date') ? '' : date
    },
    formatDay (date) {
      return this.formatDate(date, 'MM-DD')
    },
    formatMonth (month) {
      return this.formatDate(month, 'MM月')
    },
    initData () {
      let token = this.$route.query['token']
      setSessionStorage('token', token)
    },
    handleActivateZr (params) {
      let pointInPixel = [params.offsetX, params.offsetY]
      if (!this.eChart.containPixel('grid', pointInPixel)) {
        return
      }

      let xIndex = this.eChart.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[0]

      this.eChart.dispatchAction({ type: 'hideTip' })
      this.chartOption.baseOption.series[0].data[this.currentItem.index].symbol = 'emptyCircle'
      this.currentItem = {
        index: xIndex,
        date: this.data[xIndex].date,
        amount: this.data[xIndex].money
      }

      this.chartOption.baseOption.series[0].data[xIndex].symbol = 'circle'
      this.eChart.setOption(this.chartOption)
    },
    handleActivate (params) {
      let xIndex = params.dataIndex
      this.chartOption.baseOption.series[0].data[this.currentItem.index].symbol = 'emptyCircle'
      this.currentItem = {
        index: xIndex,
        date: this.data[xIndex].date,
        amount: this.data[xIndex].money
      }

      this.chartOption.baseOption.series[0].data[xIndex].symbol = 'circle'
      this.eChart.setOption(this.chartOption)
    },
    getToolTipContent (params, ticket, callback) {
      return this.currentItem.date
    },
    initChart () {
      this.eChart = this.$echarts.init(document.getElementById('e-chart'))
      window.addEventListener('resize', () => {
        this.eChart.resize()
      })
    },
    updateChart () {
      let groupTotal = this.chartType === 'day' ? getPerformanceByDay : getPerformanceByMonth
      let fakeChartMoney = this.chartType === 'day' ? [0, 0, 0, 0, 0, 0, 0] : [0, 0, 0, 0, 0, 0]
      groupTotal(JSON.stringify({})).then(res => {
        if (res.data.result !== 0) {
          return
        }

        this.data = []
        let chartTime = res.data.data.xtags
        let chartMoney = res.data.data.amounts

        for (let i = 0; i < chartMoney.length; i++) {
          this.showBlankChart &= chartMoney[i] === 0
        }

        this.showBlankChart = false

        chartMoney = this.showBlankChart ? fakeChartMoney : chartMoney
        for (let j = 0; j < chartMoney.length; j++) {
          this.data.push({
            date: this.showBlankChart ? '0' : chartTime[j],
            money: chartMoney[j].toFixed(2)
          })
        }

        this.chartOption.baseOption.xAxis.data = []
        this.chartOption.baseOption.series[0].data = []
        let index = this.data.length - 1
        for (let k = 0; k < this.data.length; k++) {
          this.chartOption.baseOption.xAxis.data.push(this.chartsData[k].date)
          this.chartOption.baseOption.series[0].data.push({ value: this.data[k].money, symbol: k !== index ? 'emptyCircle' : 'circle' })
        }

        this.currentItem = {
          index: index,
          date: this.data[index].date,
          amount: this.data[index].money
        }

        this.eChart.setOption(this.chartOption)
      })
    }
  },
  watch: {
    chartType () {
      this.updateChart()
    }
  },
  mounted () {
    if (this.initData) {
      this.initData()
    }

    this.initChart()
    this.updateChart()

    this.eChart.on('click', this.handleActivate)
    this.eChart.getZr().on('click', this.handleActivateZr)
    this.eChart.getZr().on('mousemove', this.handleActivateZr)
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index";
.chart-h5-wrap {
  min-height: 100%;
  background: #fff;
}

.chart-tab {
  border-bottom: solid 1px #e4e4e4;
  display: flex;

  .active {
    border-bottom: solid px2rem(4px) #4081d6;
  }

  &-item {
    width: 50%;
    height: px2rem(88px);
    line-height: px2rem(88px);
    vertical-align: middle;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    font-size: px2rem(28px);
    color: #363636;
    cursor: pointer;

    &:first-child {
      margin-left: px2rem(-16px);
    }

    &:last-child {
      margin-right: px2rem(-16px);
    }
  }

  @media screen and (max-width: 360px) {
    &-item {
      font-size: px2rem(26px);
    }
  }
}

.chart-body {
  width: 100%;
  text-align: center;
}

.summary {
  margin-top: px2rem(66px);

  span {
    display: inline-block;
    width: 100%;

    &:first-child {
      color: #757575;
      font-size: px2rem(24px);
      margin-bottom: px2rem(15px);
    }

    &:nth-child(2) {
      font-size: px2rem(64px);
      color: #fc4c5a;
      margin-bottom: px2rem(40px);

      &:before {
        content: "\00A5"; /* ￥符号 */
        margin-right: px2rem(8px);
        font-size: px2rem(45px);
      }
    }
  }
}

#e-chart {
  width: 100%;
  min-height: px2rem(550px);
}
</style>
